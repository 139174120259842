.model-outer{
.display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  h2 {
    padding: 0px 0 20px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  
    .modal-main {
      position: fixed;
      background: white;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
    padding: 40px 40px;
    }
    .close_icn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 21px;
      cursor: pointer;
    }
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
  }
  

}
.overflow-hidden {
    overflow: hidden;
  }

 .ask-customer-popup {
    .modal-main{
        width: 760px;
    }
   
    .btn-primary{
        min-width: 330px;
        margin-top: 20px;
    }
 }

 .ask-customer-msg-box{
    textarea{
        height: 230px;
    }
    label{
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        display: block;
        color: $heading-color;
    }
    .reset-txt{
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        text-align: right;
        display: block;
        color: $accent-color;

    }
}
.attech-doc-list{
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        padding: 20px 0;

    }
    .custom-control{
       padding-bottom: 15px;
    }
    .ic-peson-listed{
        font-size: 16px;
        margin-left: 5px;
        color: #2ABB64;
    }
    .custom-control-label{
        display: flex;
        align-items: center;
    }
}
 .doc-share-link{
    ul{
        display: flex;
        align-items: center;
        padding-bottom: 25px;

        li{
            border: 1px solid #DCDCDC ;
            box-shadow: 0px 3px 4px 0px #0000000A;
            width: 180px;
            height: 48px;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            margin-right: 15px;
            color: $heading-color;
            border-radius: 10px;
            cursor: pointer;
            img{
                margin-right: 10px;
            }

            &.active{
                border: 1px solid #008CF0
            }

        }
    }
 }