.if-applications{
.tab-content {
    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }
}

.lead-detail-tabs {
    padding: 0px 0 16px;
    width: 100%;

    .menu-tab-left-panel {
        width: 270px;
        background: $white;
        padding: 25px 33px;
        position: relative;
        box-shadow: 0px 2px 4px 0px #0000000D;

        ul {
            li {
                position: relative;

                a {
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 20px;
                    display: inline-block;
                    width: 100%;

                    .img-type {
                        background: #DEE5EF;
                        border: 2px solid #DEE5EF;
                        height: 10px;
                        width: 10px;
                        margin-top: 5px;
                        margin-left: -15px;
                        margin-right: 15px;
                        vertical-align: top;
                        display: inline-block;
                        position: relative;
                        border-radius: 50%;
                        z-index: 2;
                    }

                    &.active {
                        color: $heading-color;

                        .img-type {
                            background: #DEE5EF;
                            border: 2px solid #008CF0;
                            position: relative;
                        }
                    }

                    &.completed {
                        color: $heading-color;

                        .img-type {
                            background: #19B657;
                            border: 2px solid #19B657;
                            position: relative;
                        }
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

            }
        }

    }


    .detail-form-sec {
        display: flex;
    }
}

h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: $heading-color;
}

.lost-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $white;
    background: #ff8a8a;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 13px;
    border: 1px solid #ff8a8a;
    cursor: pointer;
}

.route-files {
    width: calc(100% - 270px);
    padding: 0px 20px;
}

.form-tab-right-panel {
    background: $white;
    padding: 30px;
    margin-top: 20px;
    box-shadow: 0px 2px 4px 0px #0000000D;
    border-radius: 10px;
    h2{
        font-size: 18px;
    }
}

.link-hyperlink {
    a {
        position: absolute;
        left: 0;
        top: 14px;
        padding: 0px 15px;
    }

    span {
        position: absolute;
        left: 0;
        top: 14px;
        padding: 0px 15px;
    }

    .form-label:before {
        top: -9px !important;
        background: $white;
    }
}

button.lost-btn {
    &.btn-cancel-do {
        position: absolute;
        right: 20px;
        background: #ffa340;
        border: 1px solid #ffa340;
    }
}

.relative {
    position: relative;
}

.btn-continue {
    border-top: 1px solid rgba($heading-color, 0.1);
    text-align: center;
    padding: 30px 30px 0;
    width: calc(100% + 60px);
    margin: 25px -30px 0px;
    display: inline-block;

    .btn-primary {
        min-width: 275px;
    }
}

.lead-top-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .subheading{
        font-size: 14px;
        margin-left: 5px;
    }
}

.doc-upload-main {
    .doc-heading {
        background: #E1E8F3;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        padding: 10px 20px;
        color: $heading-color;
        display: block;
        width: 100%;

    }
}

.close-icn {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;

    .ic-clearclose {
        background: rgba(31, 31, 31, 0.6);
        height: 16px;
        width: 16px;
        border-radius: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
    }
}

.img-bx-select-opt {
    position: relative;
    margin: 15px 17px 10px;
    width: calc(20% - 34px);

    .image-bx {
        height: 140px;
    }

    &:hover {
        .close-icn {
            opacity: 1;
        }
    }

    .custom-control-label {
        display: flex;
    }

    .image-bx {
        background: #f4f6fb;
        border-radius: 6px;
        padding: 10px;
        height: 114px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        overflow: hidden;

        img {
            background-size: cover;
        }
    }
}

.more-file-upload {
    flex-direction: column;

    p {
        display: block;
        font-size: 11px;
        color: rgba($heading-color, 0.7);
        text-align: center;
    }

    input[type="file"] {
        opacity: 0;
        height: 140px;
        width: 100%;
        outline: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        cursor: pointer;
    }

    .ic-add {
        font-size: 30px;
    }

}

.video-play-icn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
}

.upload-doc-img {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px -17px;
    margin-bottom: 20px;
}

.ask-coustmer-btn {
    min-width: auto;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        margin-right: 5px;
        font-size: 16px;
    }
}


[hidden] {
    display: none;
}



.send-insurnace-doc-list {
    .accordion__button::after {
        // position: absolute;
        content: "\e917";
        // right: 20px;
        font-size: 25px;
        font-family: "dc" !important;
        color: $heading-color;
    }

    .accordion__button[aria-expanded='true']::after,
    .accordion__button[aria-selected='true']::after {
        content: "\e91a";
        font-family: "dc" !important;
    }

    .accordion {
        position: relative;
    }

    .accordion__button {
        background: transparent;
        border-radius: 8px;
        padding: 0px 0px;
        display: flex;
        align-items: center;

        h3 {
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            color: $heading-color;
            padding: 20px 0;
        }
    }

    .accordion__item {
        margin-bottom: 15px;
    }

    .accordion__panel {
        animation: fadein 0.35s ease-in;
        padding-left: 30PX;
    }

    .attech-doc-list {
        background: #ECF1F8;
        display: inline-block;
        width: 100%;
        border-radius: 8px;

        .custom-control-label::before {
            background: rgba($white, 0.54)
        }

        .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: $accent-color;
            background-color: $accent-color;
        }
    }

}

.radio-type-txt {
    align-items: center;

    .form-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        margin-right: 15px;
    }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 60px !important;
}

.react-datepicker__time-container {
    width: 60px !important;
}

.estimate-detail-sec {
    display: inline-block;
    width: 100%;
    border-top: 1px solid $border-color;
    padding: 40px 30px 0;
    width: calc(100% + 60px);
    margin: 20px -30px 0px;


    h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        padding-bottom: 20px;

    }

    .attech-doc-view {
        border: 1px solid #C1C1C1;
        background: $white;
        color: $heading-color;

        i {
            color: $heading-color;
        }

        p {
            color: $heading-color;
        }

        .ic-cancel {
            position: absolute;
            right: 0px;
        }
    }

    .btn-line {
        min-width: auto;
        width: 100%;
        height: 54px;
        font-size: 13px;

        i {
            margin-right: 5px;
            font-size: 16px;
        }
    }
}

.Evaluation-Letter-popup {
    .modal-main {
        width: 580px;
    }
}

.attech-repair-doc {
    border: 1px solid $accent-color;
    color: $accent-color;
    min-width: 105px;
    background: rgba($accent-color, 0.05);
    display: inline-flex;
    align-items: center;
    height: 54px;
    border-radius: 8px;
    width: 100%;
    position: relative;
    justify-content: center;

    p {
        font-size: 13px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $accent-color;

    }

    i {
        color: $accent-color;
        margin-right: 5px;

    }

    input[type="file"] {
        opacity: 0;
        height: 54px;
        width: 100%;
        outline: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        cursor: pointer;
    }

}

.add-repait-doc-txt {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: $accent-color;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
        margin-left: 5px;
        font-size: 16px;
    }
}

.yes-no-option {
    ul {
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        padding-top: 15px;

        li {
            box-shadow: 0px 3px 4px 0px #0000000A;
            border: 1px solid #DCDCDC;
            width: 180px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: $heading-color;
            border-radius: 10px;
            margin-right: 10px;
            cursor: pointer;

            i {
                margin-right: 5px;
                font-size: 20px;
            }

            &.active {
                border-color: $accent-color;
                color: $accent-color;
            }

            .no-active {
                border-color: #E43232;
                color: #E43232;
            }
        }
    }
}


// -----------
.lead-detail-right-btn {
    display: flex;
    align-items: center;

    .notifiavtion-icn {
        position: relative;
        margin-right: 10px;
        cursor: pointer;

        i {
            font-size: 30px;
        }

        .badge {
            background: #F0573B;
            height: 18px;
            width: 18px;
            border-radius: 18px;
            font-size: 10px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            position: absolute;
            top: -4px;
            right: -5px;


        }
    }

    .history-btn {
        height: 32px;
        width: 32px;
        background: $white;
        border: 1px solid #8C8C8C;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.list-post-disbural-doc{
    border-right: 1px solid $border-color;
    ul{
        
        li{
            display: flex;
            padding-bottom: 10px;
            align-items: center;
            i{
                color: #3BC04D;
                margin-right: 8px;
                position: relative;
                top: 2px;
                font-weight: bold;
                font-size: 14px; 
                &.ic-clearclose {
                    color: #ff2e2e;
                }
            }
            span{
                font-size: 13px;
                font-weight: 400;
                color: $heading-color;
            }
        }
    }
}
.image-banner-outer{
    .verfication-img {
        padding: 0 0 10px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    
    .image-opt {
        // position: absolute;
        // bottom: 20px;
        width: 100%;
        // left: 0;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        padding: 0px 15px;
        width: 100%;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            li {
                padding: 0px 5px;
                a {
                    background: #ffffff;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                    border-radius: 10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 20px;
                }
                .ic-history {
                    font-size: 17px;
                }
            }
        }
        .btn-submission-approved {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            
            .btn-line {
                min-width: auto;
                padding: 0px 10px;
                height: 34px;
                line-height: 31px;
                font-size: 12px;
                font-weight: normal;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        &.doc-image-opt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            ul {
                margin-bottom: 0px;
            }
            .btn-submission-approved {
                justify-content: flex-end;
            }
        }
    }
}
.btn-next-edit{
    button{
        min-width: 160px !important;
    }
}

.br-right{
    border-right: 1px solid $border-color;
}

.br-left{
    border-left: 1px solid $border-color;
}
.add-remark-txt-add-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    label{
        font-size: 13px;
        font-weight: 400;
    }
    span{
        color: #0BBDDD;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
}
.credit-doc-list-outer{
    border: 1px solid #E0E0E0;
    background: rgba($white, 0.54);
    border-radius: 8px;
    table{
        font-size: 12px;
        th{
            background: #E3E7F1;
            &:first-child{
                border-radius: 8px 0 0 0;
            }
            &:last-child{
                border-radius: 0px 8px 0 0;
            }
        }
        th,td{
            text-align: left;
            padding: 20px;
            &:first-child{
                width: 70%;
            }
            &:last-child{
                text-align: right;
            }
            input{
                width: 100px;
                border: 0px;
                font-size: 12px;
                text-align: right;
            }
        }
        tbody{
            td{
                padding: 10px 20px;
            }
            tr{
                border-bottom: 1px solid $border-color;
            }
        }

    }
}
.tab-btn-style{
    .tab-list{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .nav-item{
            a{
                background: #EAECF3;
                &.active{
                    background: #0bbddd;
                }
            }
        }
    }
}
.ToggleSwitch {
    position: relative;
    // width: 5rem;
    // height: 2.35rem;
    // display: inline-block;

    &.ToggleSwitch__rounded {
        .Slider {
            border-radius: 15rem;
            background: #eceff1;
            border: 1px solid #a0a0a0;

            &:before {
                border-radius: 50%;
            }
        }
    }

    .ToggleSwitch__wrapper {
        position: relative;
        width: 35px;
        height: 18px;

        .Slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s ease;

            &:before {
                width: 16px;
                height: 16px;
                position: absolute;
                background: #fff;
                content: "";
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0.1rem;
                transform: translateY(-55%);
                transition: 0.4s;
                cursor: pointer;
                // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }

            &.isChecked {
                background: #0bbddd;
                border-color: #0bbddd;

                &:before {
                    left: calc(100% - 0.1rem - 1rem);
                    background: #fff;
                }
            }
        }
    }
}
.switch-txt{
        font-size: 13px;
        font-weight: 400;
        color: $heading-color;

}
.credit-line-radio{
    display: flex;
    align-items: center;
}
.btn-auto-width{
    min-width: auto !important;
    padding: 0px 15px;
}
.doc-upload-table{
    ul{
        display: flex;
        align-items: center;
        li{
            margin: 0px 0 0 5px;
            width: auto;
            &:last-child{
                margin-right: 0px;
            }
           &.img-bx-select-opt{
            .image-bx{
                padding: 0px;
                width: 48px;
                height: 36px;
                margin: 0px;
                overflow: hidden;
            }
           } 
        }
    }
}
.onboarding-list-table{
table{
    th,td{
        &:nth-child(7){
            width: 14%;
        }
    }
}
}
.display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  
    .modal-main {
      position: fixed;
      background: white;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .close_icn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 21px;
      cursor: pointer;
    }
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
.mark-lost-popup {
    .modal-main {
      border-radius: 8px;
      width: 480px;
      padding: 40px 40px;
      h2 {
        padding: 0px 0 20px 0px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
      }
    }
    .modal-body {
      padding-top: 7px;
      overflow-y: inherit;
    }
    fieldset {
      margin-bottom: 20px;
    }
  }
  .ic-fullscreen{width:auto; height: auto; background-position: -0px 0px;}

[hidden] {
    display: none;
}

.accordion__button:before {
    position: absolute;
    content: '+';
    right: 20px;
    font-size: 30px;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    content: '-';
}

.accordion__button {

    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
    padding: 20px;
}

.accordion__item {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .accordion__button {
        background: #E3E7F1;
        border-radius: 8px;
        padding: 10px 60px 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $heading-color;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }
    }

}


.img-bx-select-opt-regular {
    position: relative;
    margin: 15px 17px 10px;
    width: 200px;

    .image-bx {
        height: 140px;
    }

    &:hover {
        .close-icn {
            opacity: 1;
        }
    }

    .custom-control-label {
        display: flex;
    }

    .image-bx {
        background: #f4f6fb;
        border-radius: 6px;
        padding: 10px;
        height: 114px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        overflow: hidden;

        img {
            background-size: cover;
        }
    }
}