.if-applications{
.loan-filter-bg{
    background: $white;
    border-radius: 10px;
    padding: 20px;
    .filter-filed-form{
        display: flex;
        align-items: center;
        .single-select{
            margin-bottom: 0px;
        }
    }
    fieldset{
        width: 100%;
        margin-right: 10px;
        margin-bottom: 0px;
    }
}

.lead-list-outer{
    margin: 15px 0;
}
.list-filter-notification {
    background: $white;
    padding: 30px 20px;
    width: 324px;
    border-radius: 10px;

    .filter-outer {
        h2 {
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;

        }
        .radio-type-txt{
            label{
                font-size: 13px;
                color: $heading-color;
                padding-bottom: 10px;
                display: block;
            }
        }
        .custom-checkbox{
            margin-right: 30px;
        }
    }
    .claim-notification-outer{
        width: 100%;
        padding: 50px 0px 0;
        h2{
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            padding-bottom: 10px;
        }
        .notification-list-content{
            background: #F0F4F9;
             border-radius: 10px;
            padding: 30px 20px;
            background: #F0F4F9;

        }
        
    }
}
.full-width{
    .btn-primary{
        width: 100%;
        min-width: auto;
    }
}

.search-bx {
    .material{
        .form-input {
            padding-left: 45px;
        }
        i {
            font-size: 20px;
            left: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
} 

.claim-list-rigt-sec{
    width: calc(100% - 344px);
    padding-left: 20px;
    .insurance-claim-list-outer{
        .search-bx{
            width: 400px;
        }
        background: $white;
        border-radius: 10px;
        padding: 20px 0;
        margin-top: 20px;
        height: calc(100% - 41px);
        .btn-right{
            i {
                font-size: 16px;
                padding-right: 5px;
            }
            .btn-line, .btn-primary {
                align-items: center;
                display: flex;
                padding: 0 20px;
                min-width: auto;
            }
          
        }
        .search-panel-btn{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px 20px;
        }
        table{
            tr{
                th,td{
                    &:first-child{
                        width: 14%;
                    }
                    &:nth-child(2){
                        width: 10%;
                    }
                    &:nth-child(4){
                        width: 16%;
                    }
                    &:nth-child(5){
                        width: 7%;
                    }
                }
            }
        }
    }
   
}
.add-application-popup{
    .modal-main{
        width: 1036px;
    }
}
.btn-submit-reset {
    display: flex;
    align-items: center;
    .btn-primary {
        min-width: 98px;
        margin-right: 10px;
    }
    .btn-reset {
        cursor: pointer;
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: $heading-color;
    }
    &.more-filter-btn {
        margin-top: 20px;
        .btn-primary {
            min-width: 171px;
            margin-right: 10px;
        }
    }
}

.nav-pills {
    display: flex;
    align-items: center;
    .nav-item {
        a {
            background: $white;
            border-radius: 100px;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            height: 36px;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            span{
                background: rgba(227, 231, 241, 0.60);
                height: 22px;
                border-radius: 12px;
                padding: 0px 8px;
                margin-left: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active {
                background: #0bbddd;
                color: $white;
                span{
                    background: rgba(0, 151, 178, 0.60);
                }
            }
        }
    }
}
.tab-content {
    .tab-pane {
        display: none;
        &.active {
            display: block;
        }
    }
}


.lead-list-table-outer{
    .tab-list{
         display: flex;
         align-items: center;
         padding: 20px 0;
         white-space: nowrap;
         overflow-x: auto;
    }
    .data-table{
        background: $white;
        border-radius: 6px;
    }
    table{
         td{
            vertical-align: top;
            .dealer-table-detail-txt{
                label{
                    color: $heading-color;
                }
                span{
                    display: block;
                }
            }
        }
        th,td{
           width: 10%;
           &:nth-child(2){
            width: 15%;
        }
           
        }
    }
}
}