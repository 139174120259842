@charset "UTF-8";
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0px;
  margin-bottom: 15px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

body {
  font-family: Roboto, Arial, sans-serif;
}

/*Font Size's*/
/* Margin Variables*/
/*Width*/
/* Padding Variables*/
/* Float's*/
/* Text Aligment's*/
/* Text Decoration*/
/* Display */
/* Opacity */
/* List Style none */
/* Font Weight*/
/* Cursor*/
/* Position  */
/*  modal */
/* break point and media */
/* Tooltips*/
/* popover*/
/*Dashboard*/
/* visibility  */
/* Cursor*/
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.428571429;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #000;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, ul, ol, dl, dt, dd, pre, form, fieldset, p, blockquote, th, td {
  margin: 0;
  padding: 0px;
}

li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

/* HTML5 Tag */
article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

/* Anchor  */
a {
  background-color: transparent;
  color: #2176AE;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
}

a:active, a:hover, a:focus {
  outline-width: 0;
}

a img {
  border: none;
  display: block;
}

/* Set Default Property */
mark {
  background-color: #ff0;
  color: #000000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

code, kbd, pre, samp {
  font-family: inherit;
  font-size: 1em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

/* Form Elements */
figure {
  margin: 1em 40px;
}

button, input, select, textarea {
  font: inherit;
  margin: 0px;
}

optgroup {
  font-weight: bold;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0px;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after,
header:before,
header:after,
footer:before,
footer:after {
  display: table;
  content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after,
header:after,
footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

/*Common Classes*/
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}
table {
  background-color: transparent;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 15px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 26px;
  padding-top: 15px;
}

h2, .h2 {
  font-size: 28px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

p {
  margin-bottom: 15px;
}

.alert {
  font-size: 14px;
  border: dashed 1px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
}
.alert.alert-default {
  border: dashed 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.54);
  background: rgba(0, 0, 0, 0.04);
}
.alert.alert-primary {
  border: dashed 1px rgba(228, 101, 54, 0.2);
  color: rgba(228, 101, 54, 0.87);
  background: rgba(228, 101, 54, 0.08);
}

.text-green {
  color: #31B93E;
}

.text-blue {
  color: #2176AE;
}

.text-red {
  color: #E24141;
}

.list-inline li {
  display: inline-block;
}

.text-primary {
  color: #E46536;
}

.text-danger {
  color: #E24141;
}

.text-success {
  color: #31B93E;
}

.mrg-b15 {
  margin-bottom: 15px;
}

.pd-0 {
  padding: 0px !important;
}

.pad-l30 {
  padding-left: 30px;
}

.pd-lr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pad-t30 {
  padding-top: 30px;
}

.pad-t15 {
  padding-top: 15px;
}

.pad-t10 {
  padding-top: 10px;
}

.pad-15 {
  padding: 15px;
}

.pad-t25 {
  padding-top: 25px;
}

.mrg-b0 {
  margin-bottom: 0px;
}

.mrg-b5 {
  margin-bottom: 5px;
}

.mrg-b10 {
  margin-bottom: 10px;
}

.mrg-b20 {
  margin-bottom: 20px;
}

.mrg-t15 {
  margin-top: 15px;
}

.mrg-t30 {
  margin-top: 30px;
}

.mrg-t25 {
  margin-top: 25px;
}

.mrg-r5 {
  margin-right: 5px;
}

.mrg-r15 {
  margin-right: 15px;
}

.mrg-r30 {
  margin-right: 30px;
}

.mrg-l5 {
  margin-left: 5px;
}

.mrg-l10 {
  margin-left: 10px;
}

.mrg-l15 {
  margin-left: 15px;
}

.f12 {
  font-size: 12px;
}

.link-text {
  text-transform: uppercase;
  color: #E46536;
  font-weight: 500;
  cursor: pointer;
}

.listingdate {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.pricefont {
  font-size: 20px;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

.text-light {
  opacity: 0.37;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.37);
}
.label.gray-status {
  background: #a9a9a9 !important;
}
.label.yellow-status {
  background: #f9c834 !important;
}
.label.orange-status {
  background: #ff9c4c !important;
}
.label.blue-status {
  background: #70bee2 !important;
}
.label.green-status {
  background: #96c783 !important;
}
.label.red-status {
  background: #f47a7a !important;
}

.contentbreak {
  padding-top: 60px;
  padding-bottom: 15px;
  font-size: 20px;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker-popper {
  min-width: 330px;
}

.edit-mobile {
  position: relative;
}
.edit-mobile .edit-mobile-link {
  position: absolute;
  right: 30px;
  top: 36px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #E46536;
  cursor: pointer;
}

.delete-user {
  position: absolute!important;
  right: 60px;
  top: 10px;
}

.body-hidden {
  overflow: hidden;
}

.document-link {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.03);
  padding: 2px 10px 2px 2px;
}
.document-link a {
  display: flex;
  align-items: center;
}

.delete-docs {
  margin-left: auto;
  width: 24px;
  text-align: center;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.delete-docs:hover {
  background: rgba(0, 0, 0, 0.12);
  opacity: 0.87;
}
.delete-docs .ic-clearclose {
  font-size: 16px;
}

.loader {
  position: relative;
}
.loader.page-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.54);
  z-index: 997;
}
.loader.content-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.54);
  z-index: 997;
}

.loading {
  position: relative;
  pointer-events: none;
}
.loading:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.54);
  z-index: 997;
}
.loading:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  border: 8px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  border-top: 8px solid #E46536;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  z-index: 998;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icons-normal {
  font-size: 20px;
}

.icons-sm {
  font-size: 16px;
}

.icons-lg {
  font-size: 28px;
}

.brdr-left {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

.brdr-right {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

/* Media Queries */
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.ic-delete {
  width: 8px;
  height: 8px;
  background-position: 0px 0px;
}

.ic-close-w {
  width: 8px;
  height: 8px;
  background-position: 0px 0px;
}

.ic-close-b {
  width: 8px;
  height: 8px;
  background-position: -37px 0px;
}

.ic-rotate {
  width: 8px;
  height: 8px;
  background-position: -9px 0px;
}

.ic-help {
  width: 14px;
  height: 14px;
  background-position: -19px 0px;
}

.ic-kms {
  width: 17px;
  height: 16px;
  background-position: -82px -15px;
}

.ic-regdate {
  width: 16px;
  height: 16px;
  background-position: 0px -34px;
}

.ic-owner {
  width: 16px;
  height: 16px;
  background-position: -34px -15px;
}

.ic-fuel {
  width: 16px;
  height: 16px;
  background-position: -18px -15px;
}

.ic-regno {
  width: 16px;
  height: 16px;
  background-position: -54px -34px;
}

.ic-transmission {
  width: 16px;
  height: 16px;
  background-position: -65px -15px;
}

.ic-color {
  width: 16px;
  height: 16px;
  background-position: 0px -15px;
}

.ic-noreg {
  width: 16px;
  height: 16px;
  background-position: -18px -34px;
}

.ic-edit-gray {
  width: 16px;
  height: 16px;
  background-position: 0px -53px;
}

.ic-delete-gray {
  width: 15px;
  height: 16px;
  background-position: -18px -53px;
}

.ic-featured-gray {
  width: 15px;
  height: 16px;
  background-position: -33px -53px;
}

.ic-share-gray {
  width: 16px;
  height: 16px;
  background-position: -66px -53px;
}

.ic-report-gray {
  width: 15px;
  height: 16px;
  background-position: -86px -53px;
}

.ic-plus-gray {
  width: 16px;
  height: 16px;
  background-position: -104px -53px;
}

.ic-info {
  width: 16px;
  height: 16px;
  background-position: -73px -98px;
}

.ic-view {
  width: 16px;
  height: 16px;
  background-position: -1px -98px;
}

.ic-reopen {
  width: 16px;
  height: 16px;
  background-position: -38px -98px;
}

.ic-history {
  width: 16px;
  height: 16px;
  background-position: -55px -98px;
}

.ic-heart {
  width: 16px;
  height: 16px;
  background-position: -73px -35px;
}

.ic-copy {
  width: 16px;
  height: 16px;
  background-position: -111px -15px;
}

.ic-next-w {
  width: 9px;
  height: 8px;
  background-position: -29px -137px;
}

.ic-prev-w {
  width: 9px;
  height: 8px;
  background-position: -19px -137px;
}

.ic-next-b {
  width: 9px;
  height: 8px;
  background-position: -10px -137px;
}

.ic-prev-b {
  width: 9px;
  height: 8px;
  background-position: 0px -137px;
}

.ic-setting {
  width: 16px;
  height: 16px;
  background-position: -139px -53px;
}

.ic-subscribe {
  width: 16px;
  height: 16px;
  background-position: -122px -53px;
}

.ic-contact {
  width: 16px;
  height: 16px;
  background-position: -157px -53px;
}

.ic-save-b {
  width: 16px;
  height: 16px;
  background-position: -175px -53px;
}

.ic-save-w {
  width: 16px;
  height: 16px;
  background-position: -175px -73px;
}

.ic-date {
  width: 16px;
  height: 16px;
  background-position: -111px -34px;
}

.ic-rupees {
  width: 16px;
  height: 16px;
  background-position: -132px -34px;
}

.ic-plus {
  width: 14px;
  height: 14px;
  background-position: -38px -118px;
}

.ic-minus {
  width: 14px;
  height: 14px;
  background-position: -58px -118px;
}

.ic-rotate {
  width: 14px;
  height: 14px;
  background-position: -19px -119px;
}

.ic-fullscreen {
  width: 14px;
  height: 14px;
  background-position: 0px -118px;
}

.source-icon {
  background-image: url(../images/sprite-gaadi.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.WALK-IN, .Walk-in {
  background-position: 1px -150px;
  width: 25px;
  height: 20px;
}

.Cardekho {
  background-position: 1px -31px;
  width: 50px;
  height: 13px;
}

.Gaadi {
  background-position: 0px 0px;
  width: 50px;
  height: 14px;
}

.cardekho_knowlarity {
  background-position: 1px -276px;
  width: 27px;
  height: 27px;
}

.verified {
  background-position: 1px -55px;
  width: 22px;
  height: 22px;
}

.CARWAL {
  background-position: 1px -86px;
  width: 24px;
  height: 14px;
}

.website {
  background-position: 1px -123px;
  width: 18px;
  height: 18px;
}

.CARTRADE {
  background-position: 1px -180px;
  width: 18px;
  height: 18px;
}

.OLX {
  background-position: 1px -211px;
  width: 21px;
  height: 12px;
}

.QUIKR {
  background-position: 1px -232px;
  width: 40px;
  height: 15px;
}

.oto, .OTO {
  width: 25px;
  height: 10px;
  background-position: 0px -334px;
}

.gcloud, .G-Cloud, .self, .SELF {
  width: 25px;
  height: 13px;
  background-position: 0px -346px;
}

.zigwheel, .Zigwheels {
  width: 25px;
  height: 11px;
  background-position: 0px -360px;
}

@media (min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5) {
  .source-icon {
    background-image: url(../images/sprite-gaadi-2x.png);
    background-size: 45px 398px;
  }
}
.card {
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  position: relative;
}
.card .card-heading {
  padding: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  position: relative;
}
.card .card-heading .card-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 15px;
}
.card .card-footer {
  padding: 15px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.card.card-success {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.card.card-success .card-heading {
  background: #31B93E;
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.card.card-danger {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.noc_doc_download {
  float: right;position: relative;top: -15px; margin-right: 10px;
}
.card.card-danger .card-heading {
  background: #E24141;
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.card.card-secondary {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.card.card-secondary .card-heading {
  background: rgba(0, 0, 0, 0.12);
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}

.panel {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.panel .panel-heading {
  padding: 0px;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  position: relative;
}
.panel .panel-heading .panel-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}
.panel .panel-body {
  padding: 15px;
}
.panel .panel-footer {
  padding: 15px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.panel.panel-gray {
  background: #F1F3F6;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.67);
  background-color: #ffffff;
}
.table th,
.table td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table tbody + tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.table-sm th,
.table-sm td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px;
  background: rgba(0, 0, 0, 0.08);
  text-align: left;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.table-hover tbody tr:hover td {
  background-color: rgba(33, 118, 174, 0.04);
}

.table-responsive {
  display: block;
  width: 100%;
}

.table-responsive > .table-bordered {
  border: 0;
}

@media (max-width: 767.98px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (min-width: 992px) {
  .table {
    word-break: break-word;
  }
}
.btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn {
  display: inline-block;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  color: #E46536;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 8px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn.btn-default {
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.btn.btn-default:hover, .btn.btn-default:focus {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.btn.btn-reset {
  background: #ffffff;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  padding: 8px 5px;
}
.btn.btn-reset:hover, .btn.btn-reset:focus {
  box-shadow: none;
  border: none;
}
.btn.btn-primary {
  background: #E46536;
  color: #ffffff;
}
.btn.btn-primary:hover, .btn.btn-primary:focus {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid transparent;
  color: #ffffff;
}
.btn.btn-success {
  background: #31B93E;
  color: #ffffff;
}
.btn.btn-success:hover, .btn.btn-success:focus {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid transparent;
  color: #ffffff;
}
.btn:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.btn.disabled:hover, .btn:disabled:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -ms-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -ms-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn.btn-link {
  padding: 0;
  color: #2176AE;
}
.btn.btn-link:hover, .btn.btn-link:focus {
  color: #E46536;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn-lg {
  padding: 12px 30px;
  font-size: 16px;
}

.btn-sm {
  padding: 4px 10px;
  font-size: 12px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 30px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group .btn-default:hover, .btn-group .btn-default:focus, .btn-group .btn-default:active {
  z-index: 1;
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: #ffffff;
  background: #e46536;
  box-shadow: none;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group.btn-group-small .btn {
  padding: 2px 4px;
}

.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 15px;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: solid 3px transparent;
  padding: 15px;
  position: relative;
  justify-content: center;
  display: flex;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0152857143em;
}
.nav-tabs .nav-item.active, .nav-tabs .nav-item:hover, .nav-tabs .nav-item:focus {
  color: #E46536;
  border-bottom: solid 3px transparent;
}
.nav-tabs .nav-item.active {
  font-weight: 500;
  border-bottom: solid 3px #E46536;
}

/*btngroup and popover*/
.btn-group .popover {
  top: 30px;
}
.btn-group .popover.bs-popover-bottom, .btn-group .popover.bs-popover-auto[x-placement^=bottom] {
  top: 30px;
}
.btn-group .popover.bs-popover-top, .btn-group .popover.bs-popover-auto[x-placement^=top] {
  top: initial;
  bottom: 30px;
}
.btn-group .popover.bs-popover-left, .btn-group .popover.bs-popover-auto[x-placement^=left] {
  top: initial;
  bottom: 30px;
}
.btn-group .popover.bs-popover-right, .btn-group .popover.bs-popover-auto[x-placement^=right] {
  top: initial;
  bottom: 30px;
}

.switch-btn {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  padding-left: 40px;
  margin-bottom: 0px;
}
.switch-btn > input[type=checkbox] {
  display: none;
}
.switch-btn > input[type=checkbox] + .slider {
  position: relative;
  cursor: pointer;
  width: 34px;
  height: 14px;
  top: 4px;
  left: 0;
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  border-radius: 32px;
}
.switch-btn > input[type=checkbox] + .slider:before {
  content: "";
  position: absolute;
  background-color: #cccccc;
  top: -3px;
  left: -3px;
  width: 20px;
  height: 20px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.switch-btn > input[type=checkbox]:checked + .slider {
  background-color: rgba(228, 101, 54, 0.3);
}
.switch-btn > input[type=checkbox]:checked + .slider:before {
  background-color: #E46536;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translate(22px, 0px);
  -moz-transform: translate(22px, 0px);
  -ms-transform: translate(22px, 0px);
  transform: translate(22px, 0px);
}
.switch-btn .switch-label {
  vertical-align: top;
}

@media (max-width: 767px) {
  .floating-btn {
    border-radius: 50%;
    content: "";
    width: 56px;
    height: 56px;
    /* text-indent: 999px; */
    overflow: hidden;
    white-space: nowrap;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 99;
    font-size: 16px;
  }

  .navbar {
    overflow: hidden;
    overflow-x: auto;
  }

  .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0px;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: inherit;
  color: rgba(0, 0, 0, 0.87);
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 3px;
  opacity: 0.87;
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}

input[type=radio],
input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}
input[type=radio][disabled], input[type=radio].disabled, fieldset[disabled] input[type=radio],
input[type=checkbox][disabled],
input[type=checkbox].disabled,
fieldset[disabled] input[type=checkbox] {
  cursor: disabled;
}

input[type=file] {
  display: block;
}

input[type=range] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  border-color: rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 1.428571429;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: none;
}
.form-control:hover, .form-control:focus, .form-control:active {
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.form-control:disabled, .form-control[readonly] {
  background: rgba(0, 0, 0, 0.12);
  opacity: 0.4;
  cursor: not-allowed;
}
.form-control:disabled:hover, .form-control:disabled:focus, .form-control[readonly]:hover, .form-control[readonly]:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.error, .form-error {
  color: #E24141;
  font-size: 12px;
  display: none;
  position: absolute;
  line-height: 12px;
}

textarea.form-control {
  height: auto;
  min-height: 60px;
  resize: vertical;
}

/* Actual code example */
.form-field {
  position: relative;
  margin-bottom: 20px;
}
.form-field input:focus + label {
  color: #E46536 !important;
}
.form-field input + label {
  position: absolute;
  top: 7px;
  left: 15px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}
.form-field.active input + label {
  top: -12px;
  opacity: 1;
  left: 10px;
  font-size: 12px;
  background: #ffffff;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.37);
}
.form-field.active input + label.error {
  color: #ec392f;
}

.form-field input::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.form-field input::-moz-placeholder, input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.form-field input:-ms-input-placeholder, input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.form-field input:-moz-placeholder, input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.form-field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-field {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  display: flex;
}
.input-group > .form-field + .form-control,
.input-group > .form-field + .custom-select,
.input-group > .form-field + .custom-file {
  margin-left: -1px;
}
.input-group > .form-field .form-control {
  border-radius: 3px 0px 0px 3px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
  border-radius: 0px 3px 3px 0px;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-prepend {
  margin-right: -1px;
}
.input-group-prepend .input-group-text {
  border-radius: 3px 0px 0px 3px;
}

.input-group > .form-field:not(:first-child), .input-group > .form-control:not(:first-child), .input-group > .form-field:not(:first-child) .form-control {
  border-radius: 0 3px 3px 0px;
}

.css-1hwfws3 {
  font-size: 16px;
}

.css-2b097c-container .css-1okebmr-indicatorSeparator {
  display: none;
}
.css-2b097c-container .css-tlfecz-indicatorContainer {
  padding: 8px 4px;
}

.error-field .form-control, .field-error .form-control, .field-error .css-yk16xz-control {
  border: solid 1px rgba(255, 0, 0, 0.37);
  background: rgba(255, 0, 0, 0.02);
}

.error-field .error, .field-error .error {
  color: #E24141;
}

.react-datepicker__input-container input {
  background-image: url(../images/date-icon.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 21px 16px;
  padding-right: 25px;
}

.rupees-field input {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text opacity='0.8' font-size='15' x='3' y='13' >Rp</text></svg>");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 21px 16px;
  padding-left: 25px;
}

@media (min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5) {
  .react-datepicker__input-container input {
    background-image: url(../images/date-icon-2x.png);
  }

  .rupees-field input {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text opacity='0.8' font-size='15' x='3' y='13' >Rp</text></svg>");
  }
}
fieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-top-color: rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 0px 15px;
  margin-top: 20px;
}
fieldset legend {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  width: auto;
  font-weight: bold;
  color: #E46536;
  border: none;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-size: 12px;
  word-wrap: break-word;
  opacity: 0;
  min-width: 40px;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.tooltip-wrap {
  display: inline-block;
  position: relative;
}
.tooltip-wrap .tooltip {
  display: none;
}
.tooltip-wrap:hover .tooltip, .tooltip-wrap.active .tooltip {
  display: block;
  opacity: 0.9;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  bottom: 0px;
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^=top] .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -7px;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^=right] .tooltip-arrow {
  left: 0;
  top: 50%;
  margin-top: -7px;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=right] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -7px;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^=left] .tooltip-arrow {
  right: 0;
  top: 50%;
  margin-top: -7px;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=left] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 5px;
  color: #ffffff;
  text-align: center;
  min-width: 50px;
  background-color: #000000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: block;
  max-width: 300px;
  min-width: 200px;
  font-size: 14px;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: solid 1px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.01);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 5px 10px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.popover-header .close {
  float: right;
  margin-left: 30px;
  opacity: 0.5;
}
.popover-header .close:hover {
  opacity: 0.87;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.54);
}

.timeline {
  list-style: none;
  padding: 0px;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  /* background-color: #eeeeee; */
  left: 8px;
  margin-left: -1.5px;
  border-left: 2px dashed #ddd;
}
.timeline > li {
  margin-bottom: 0px;
  position: relative;
}
.timeline > li > .timeline-badge {
  color: rgba(0, 0, 0, 0.87);
  width: 15px;
  height: 15px;
  line-height: 30px;
  font-size: 1.1em;
  text-align: center;
  position: absolute;
  top: 15px;
  left: 25px;
  margin-left: -25px;
  background-color: #ffffff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border: 3px solid #e66437;
}
.timeline > li > .timeline-panel {
  width: 100%;
  position: relative;
  padding-left: 30px;
}
.timeline .timeline-heading {
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.timeline .timeline-heading .timeline-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400 !important;
  margin-bottom: 5px;
}

.edit-secL {
  float: left;
  text-align: left;
  width: 20%;
}

.edit-secR {
  float: left;
  text-align: left;
  width: 80%;
  padding-left: 30px;
}
.edit-secR .cmnt {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
}
.edit-secR .statuspara {
  margin-bottom: 5px;
}

.timeline-panel p {
  margin: 0;
}

/* Style the accordion section */
.accordion__section {
  background-color: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  /* Style the accordion chevron icon */
}
.accordion__section .accordion {
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  position: relative;
}
.accordion__section .accordion.accordion:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.accordion__section .accordion:before {
  content: "+";
  position: absolute;
  right: 10px;
  top: 7px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
}
.accordion__section .accordion.active {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion__section .accordion.active:before {
  content: "–";
}
.accordion__section .accordion.active + .accordion__content {
  padding: 15px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.accordion__section .accordion__title {
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  display: block;
  width: 100%;
}
.accordion__section .accordion__icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}
.accordion__section .accordion__icon.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  padding: 0px;
  transition: max-height 0.3s ease;
}

/* Style the accordion content text */
.accordion__text {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.accrdian-bt0 .accordion__text {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.hinge {
  animation-duration: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}
.gs_nowrapcontrol {
  display: block;
  text-align: left;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}
.gs_control {
  display: block;
  text-align: left;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
}
.gs_control:hover input ~ .gs_control__indicator {
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.54);
}
.gs_control:hover input:not([disabled]):checked ~ .gs_control__indicator {
  background: #E46536;
  border: solid 1px #E46536;
}
.gs_control input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 0;
  left: 0;
  height: initial;
}
.gs_control input:focus ~ .gs_control__indicator {
  background: transparent;
}
.gs_control input:checked ~ .gs_control__indicator {
  background: #E46536;
  border: solid 1px #E46536;
}
.gs_control input:checked:focus ~ .gs_control__indicator {
  background: #E46536;
}
.gs_control input:disabled ~ .gs_control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  filter: alpha(opacity=60);
  pointer-events: none;
}
.gs_control input:checked ~ .gs_control__indicator:after {
  display: block;
}

.rc-inline .gs_control, .rc-inline .form-field {
  display: inline-block;
  margin-right: 30px;
}

.gs_control__indicator {
  position: absolute;
  top: 2px;
  left: 0px;
  height: 16px;
  width: 16px;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.54);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: default;
  -moz-transition: default;
  -ms-transition: default;
  -o-transition: default;
  transition: default;
}
.gs_control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.gs_checkbox .gs_control__indicator:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.gs_checkbox input:disabled ~ .gs_control__indicator:after {
  border-color: #7b7b7b;
}

.gs_radio .gs_control__indicator {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.gs_radio .gs_control__indicator:after {
  left: 4px;
  top: 4px;
  height: 6px;
  width: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
}
.gs_radio input:disabled ~ .gs_control__indicator:after {
  background: #7b7b7b;
}

[data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip] {
  position: relative;
}
[data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:before, [data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, 10px);
  -moz-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}
[data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:before {
  position: absolute;
  bottom: 120%;
  left: 50%;
  margin-bottom: 5px;
  padding: 7px;
  width: auto;
  max-width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background-color: #000000;
  color: #ffffff;
  content: attr(data-gs-csstooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  text-transform: capitalize;
}
[data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:after {
  position: absolute;
  bottom: 120%;
  left: 50%;
  margin-left: 0px;
  width: 0;
  border-top: 5px solid #000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
  font-size: 0px;
  line-height: 0px;
}
[data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:hover:before, [data-gs-csstooltip-wrapper$=tooltip] [data-gs-csstooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, 0px);
  -moz-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

@font-face {
  font-family: "dc";
  src: url("../fonts/dc.eot?qdj8fm");
  src: url("../fonts/dc.eot?qdj8fm#iefix") format("embedded-opentype"), url("../fonts/dc.ttf?qdj8fm") format("truetype"), url("../fonts/dc.woff?qdj8fm") format("woff"), url("../fonts/dc.svg?qdj8fm#dc") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=ic-], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dc" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-error:before {
  content: "";
}

.ic-replay:before {
  content: "";
}

.ic-call:before {
  content: "";
}

.ic-chat:before {
  content: "";
}

.ic-email:before, .ic-emailmailmarkunreadlocal_post_office:before {
  content: "";
}

.ic-location_onplaceroom:before {
  content: "";
}

.ic-alternate_email:before {
  content: "";
}

.ic-add:before {
  content: "";
}

.ic-clearclose:before {
  content: "";
}

.ic-content_copy:before {
  content: "";
}

.ic-createmode_editedit:before {
  content: "";
}

.ic-filter_list:before {
  content: "";
}

.ic-remove:before {
  content: "";
}

.ic-save:before {
  content: "";
}

.ic-save_alt:before {
  content: "";
}

.ic-access_alarms:before {
  content: "";
}

.ic-access_timequery_builderschedule:before {
  content: "";
}

.ic-brightness_highbrightness_7:before {
  content: "";
}

.ic-gps_not_fixedlocation_searching:before {
  content: "";
}

.ic-attach_file:before {
  content: "";
}

.ic-insert_drive_file:before {
  content: "";
}

.ic-insert_photoimagephoto:before {
  content: "";
}

.ic-folder:before {
  content: "";
}

.ic-keyboard_arrow_down:before {
  content: "";
}

.ic-keyboard_arrow_left:before {
  content: "";
}

.ic-keyboard_arrow_right:before {
  content: "";
}

.ic-keyboard_arrow_up:before {
  content: "";
}

.ic-color_lenspalette:before {
  content: "";
}

.ic-crop:before {
  content: "";
}

.ic-panorama_fish_eyeradio_button_unchecked:before {
  content: "";
}

.ic-remove_red_eyevisibility:before {
  content: "";
}

.ic-add_a_photo:before {
  content: "";
}

.ic-directions_car:before {
  content: "";
}

.ic-arrow_back:before {
  content: "";
}

.ic-arrow_drop_down:before {
  content: "";
}

.ic-arrow_drop_down_circle:before {
  content: "";
}

.ic-arrow_drop_up:before {
  content: "";
}

.ic-arrow_forward:before {
  content: "";
}

.ic-cancel:before {
  content: "";
}

.ic-check:before {
  content: "";
}

.ic-fullscreen:before {
  content: "";
}

.ic-fullscreen_exit:before {
  content: "";
}

.ic-menu:before {
  content: "";
}

.ic-keyboard_control:before {
  content: "";
}

.ic-more_vert:before {
  content: "";
}

.ic-refresh:before {
  content: "";
}

.ic-arrow_back_ios:before {
  content: "";
}

.ic-arrow_forward_ios:before {
  content: "";
}

.ic-notifications:before {
  content: "";
}

.ic-person:before {
  content: "";
}

.ic-person_add:before {
  content: "";
}

.ic-share:before {
  content: "";
}

.ic-check_box:before {
  content: "";
}

.ic-check_box_outline_blank:before {
  content: "";
}

.ic-radio_button_checked:before {
  content: "";
}

.ic-stargrade:before {
  content: "";
}

.ic-bookmarkturned_in:before {
  content: "";
}

.ic-delete:before {
  content: "";
}

.ic-favorite:before {
  content: "";
}

.ic-favorite_outline:before {
  content: "";
}

.ic-search:before {
  content: "";
}

.ic-settings:before {
  content: "";
}

.ic-date_range:before {
  content: "";
}

.ic-cd_R:before {
  content: "";
}

.ic-cd-Dealer2:before {
  content: "";
}

.ic-cd-fuel:before {
  content: "";
}

.ic-cd-kilometer:before {
  content: "";
}

.ic-cd-owner:before {
  content: "";
}

.ic-cd-Transmission:before {
  content: "";
}

.ic-cd-year:before {
  content: "";
}

.ic-colors:before {
  content: "";
}

.ic-compare-car:before {
  content: "";
}

.ic-india-no:before {
  content: "";
}

.ic-new-car:before {
  content: "";
}

.ic-rc-file:before {
  content: "";
}

.ic-subscription:before {
  content: "";
}

.ic-cd-featured:before {
  content: "";
}

.ic-cd-un-featured:before {
  content: "";
}

.ic-rupees-line:before {
  content: "";
}

.ic-excel-file:before {
  content: "";
}

.ic-image-file:before {
  content: "";
}

.ic-pdf-file:before {
  content: "";
}

.ic-word-file:before {
  content: "";
}

.ic-whatsapp:before {
  content: "";
}

.ic-blur_on:before {
  content: "";
}

.ic-rotate_left:before {
  content: "";
}

.ic-rotate_right:before {
  content: "";
}

.ic-stars:before {
  content: "";
}

.ic-peson-blacklist:before {
  content: "";
}

.ic-peson-listed:before {
  content: "";
}

.ic-engine:before {
  content: "";
}

.ic-bump-up:before {
  content: "";
}

.loginContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.loginSection {
  /* background-image: url(/static/media/banner_2.557db1a3.png); */
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.loginHeaderSec {
  margin: 0px;
}
.loginHeaderSec h1 {
  color: #ffffff;
  margin-bottom: 20px;
}

.login_form {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 20px;
  width: 96%;
  margin: 0 auto;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.login_form .form-control {
  height: 46px;
  font-size: 16px;
  padding: 10px 40px;
}

@media (min-width: 767px) {
  .login_form {
    width: 420px;
  }

  .loginHeaderSec {
    margin: 30px;
  }
  .loginHeaderSec h1 {
    margin-bottom: 30px;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  width: auto;
  padding: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dropdown a .caret {
  margin-left: 0.255em;
}
.dropdown a .caret::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown a .caret:empty::after {
  margin-left: 3px;
}
.dropdown ul.dropdown-menu {
  display: none;
  position: absolute;
  min-width: 130px;
  width: auto;
  z-index: 999;
  background: #ffffff;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  -o-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.3);
}
.dropdown ul.dropdown-menu li {
  float: inherit;
  position: relative;
  width: 100%;
  text-align: left;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.dropdown ul.dropdown-menu li a {
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.54);
  white-space: nowrap;
  cursor: pointer;
}
.dropdown ul.dropdown-menu li a:hover {
  color: #E46536;
  background: rgba(0, 0, 0, 0.03);
}
.dropdown ul.dropdown-menu li:last-child {
  border-bottom: none;
}
.dropdown:hover ul.dropdown-menu {
  display: block;
}
.dropdown:hover > a {
  color: #E46536;
}

.dropdown-menu {
  display: none;
}

header {
  background: #1e335e;
}
header .brand-logo {
  float: left;
  max-width: 100%;
  max-height: 32px;
}
header nav {
  float: right;
}

.App {
  padding-top: 65px;
}

header {
  background: #1e335e;
  padding-right: 15px;
  display: flex;
  align-items: start;
  width: 100%;
}
header .navbar-brand {
  float: left;
  max-width: 100%;
  max-height: 32px;
  margin: 10px 10px;
}
header .navbar-brand img {
  max-height: 32px;
}
header nav {
  margin-left: auto;
  float: inherit;
}
header nav .navbar-nav li {
  float: left;
}
header nav .navbar-nav li a {
  color: #ffffff;
  padding: 15px 20px;
  margin: 0px;
  display: block;
  border-bottom: solid 3px transparent;
}
header nav .navbar-nav li a:hover, header nav .navbar-nav li a:focus, header nav .navbar-nav li a:active {
  color: #E46536;
}
header nav .navbar-nav li a.active {
  color: #E46536;
  border-bottom: solid 3px #E46536;
}
header nav .navbar-nav li img {
  display: inline-block;
  margin-right: 8px;
}
header nav .navbar-nav li > .dropdown-menu li .active {
  border-bottom: solid 3px transparent;
}
header .hamburger-icon {
  font-size: 42px;
  color: #ffffff;
  display: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
header.header-fixed {
  position: fixed;
  z-index: 99;
  top: 0px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
  -ms-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
  -o-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
}

.language-change .btn {
  background: transparent;
  padding: 15px 5px;
  color: #ffffff;
  opacity: 0.54;
  border: none;
  outline: none;
}
.language-change .btn.btn-default {
  color: #ffffff;
}
.language-change .btn.btn-primary {
  opacity: 1;
  color: #E46536;
}
.language-change .btn:hover {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  opacity: 1;
  color: #ffffff;
}
.language-change .btn:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}
.language-change.language-login {
  text-align: right;
}
.language-change.language-login .btn {
  padding: 0px 5px;
  color: #000000;
  font-weight: 400;
}
.language-change.language-login .btn.btn-default {
  color: #000000;
}
.language-change.language-login .btn.btn-primary {
  opacity: 1;
  color: #E46536;
}
.language-change.language-login .btn.btn-primary:hover {
  color: #E46536;
}
.language-change.language-login .btn:hover {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  color: #000000;
  opacity: 1;
}

.userac {
  display: inline-block;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.userac i {
  opacity: 0.6;
}

a:hover .userac {
  color: #ffffff;
}

.myac {
  margin-left: 30px;
}

@media (max-width: 768px) {
  header .navbar-brand img {
    max-height: 36px;
  }
  header nav {
    display: flex;
    overflow: hidden;
    transform: translateX(-100%);
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background: #1e335e;
    z-index: 999;
    width: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  header nav .navbar-nav {
    width: 100%;
    padding-top: 56px;
  }
  header nav .navbar-nav li {
    display: block;
    float: initial;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }
  header nav .navbar-nav li.dropdown a .caret {
    float: right;
  }
  header nav .navbar-nav li.dropdown a:hover a, header nav .navbar-nav li.dropdown a:hover .userac, header nav .navbar-nav li.dropdown a:hover i, header nav .navbar-nav li.dropdown a:focus a, header nav .navbar-nav li.dropdown a:focus .userac, header nav .navbar-nav li.dropdown a:focus i {
    color: #ffffff;
  }
  header nav .navbar-nav li a {
    padding: 15px;
  }
  header nav .navbar-nav li a:hover, header nav .navbar-nav li a:focus, header nav .navbar-nav li a:active {
    color: #E46536;
  }
  header nav .navbar-nav li a.active {
    color: #E46536;
    border-bottom: solid 3px transparent;
  }
  header nav .navbar-nav li img {
    display: inline-block;
    margin-right: 8px;
  }
  header nav .navbar-nav li > .dropdown-menu {
    display: inherit;
    position: inherit;
    background: transparent;
    margin-bottom: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  header nav .navbar-nav li > .dropdown-menu li {
    color: #ffffff;
    border: none;
  }
  header nav .navbar-nav li > .dropdown-menu li a {
    color: #ffffff;
    opacity: 0.54;
    padding: 5px;
    padding-left: 30px;
  }
  header nav .navbar-nav li > .dropdown-menu li .active {
    border-bottom: solid 3px transparent;
  }
  header nav.open {
    display: flex;
    height: 100%;
    overflow-y: auto;
    transform: translateX(0);
    width: 80%;
    -webkit-box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.37);
    -ms-box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.37);
    -o-box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.37);
  }
  header .hamburger-icon {
    display: initial;
    z-index: 1000;
  }

  .myac {
    margin-left: 0px;
  }
}
footer .footer-container {
  height: auto;
  color: rgba(0, 0, 0, 0.37);
  font-size: 12px;
  padding: 15px;
}
footer ul.footer-dealer li {
  display: inline-block;
  padding: 0px 5px;
}
footer span {
  padding-right: 5px;
  padding-left: 5px;
  display: inline-block;
}
footer.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .page-minheight {
    min-height: 500px;
  }
}
@media (min-width: 1200px) {
  .page-minheight {
    min-height: 550px;
  }
}
.bg-usfulltips {
  background: rgba(228, 101, 54, 0.04);
  padding: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #e1e0d0;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
}
.bg-usfulltips ul {
  padding: 0px;
  list-style: none;
}
.bg-usfulltips ul li {
  padding-left: 35px;
  background-image: url(../images/tick.png);
  background-repeat: no-repeat;
  margin-bottom: 10px;
  line-height: 1.2987012991;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.addStock_DC h1 {
  font-size: 28px;
  padding-top: 15px;
}
.addStock_DC h6 {
  font-size: 14px;
  padding-top: 15px;
}
.addStock_DC label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.addStock_DC label.gs_control {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.addStock_DC i {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.addStock_DC i.notes {
  display: block;
  margin-top: -20px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.fileuploadbox .dropzone {
  border: dashed 2px rgba(0, 0, 0, 0.12);
  min-height: 280px;
  background: rgba(0, 0, 0, 0.02);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 35px 15px 15px 15px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.fileuploadbox .dropzone:hover {
  border: dashed 2px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
}
.fileuploadbox .dropzone p {
  text-align: center;
  margin-bottom: 0px;
}
.fileuploadbox .deleteImg, .fileuploadbox .rotateImg {
  position: absolute;
  top: -4px;
  color: #ffffff;
  right: -4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.54);
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
}
.fileuploadbox .deleteImg i, .fileuploadbox .rotateImg i {
  color: #ffffff;
}
.fileuploadbox .rotateImg {
  right: inherit;
  left: -2px;
  background: #000000;
}
.fileuploadbox aside .List {
  position: relative;
}
.fileuploadbox aside .List:after {
  display: none;
  content: "Profile Photo";
  position: absolute;
  top: 0;
  left: 0;
  background: #31B93E;
  width: 80px;
  height: 18px;
  padding: 2px 5px;
  font-size: 12px;
  color: #ffffff;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  -ms-border-radius: 0 2px 2px 0;
  -o-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}

.fileuploadbox aside .stock_image_list div > div > div {
  cursor: move;
}
.fileuploadbox aside .stock_image_list div > div > .deleteImg {
  cursor: pointer;
}
.fileuploadbox aside .List .draggable > div > div {
  cursor: move;
}
.fileuploadbox aside .List .draggable .deleteImg {
  cursor: pointer;
}
.fileuploadbox aside .List .draggable .deleteImg:hover {
  background: #000000;
}
.fileuploadbox aside .List .draggable.dragged:after {
  display: none;
}
.fileuploadbox aside .List .draggable:hover > div {
  border: dashed 1px rgba(0, 0, 0, 0.54) !important;
}
.fileuploadbox aside + p.text-light {
  display: none;
}
.fileuploadbox.updated .dropzone {
  min-height: 30px;
  padding: 15px;
}
.fileuploadbox.updated aside .List:after {
  display: block;
}
.fileuploadbox.updated aside + p.text-light {
  display: block;
}
.fileuploadbox .dragged {
  opacity: 0.7;
}
.fileuploadbox .placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.12);
}

.pointer-list {
  display: flex;
  flex: 0 1 auto;
  align-items: flex-start;
  justify-content: space-around;
}
.pointer-list li {
  padding: 0 15px;
}
.pointer-list li:first-child {
  padding-left: 0px;
}
.pointer-list li:last-child {
  padding-right: 0px;
}
.pointer-list li .pointer-detail {
  display: block;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.87);
}
.pointer-list li .pointer-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.car-list {
  font-size: 14px;
}
.car-list > li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.car-list > li > .wrap {
  position: relative;
  padding: 15px 5px;
}
.car-list > li > .wrap h2.carname {
  font-size: 16px;
  margin-bottom: 5px;
}
.car-list > li > .wrap .img-box .img_thumb {
  background: #f5f5f5;
  line-height: 170px;
}
.car-list > li > .wrap .img-box .img_thumb img {
  max-height: 170px;
}
.car-list > li > .wrap .editprice .input-group label {
  display: none;
}
.car-list > li .actionbtn {
  display: none;
}
.car-list > li:hover > .wrap {
  background: rgba(228, 101, 54, 0.04);
}
.car-list > li:hover .actionbtn {
  display: block;
}
.car-list > li:before, .car-list > li:after {
  contain: "";
  display: table;
}
.car-list > li:after {
  clear: both;
}

.carpoints {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 15px;
  padding-left: 15px;
  min-height: 110px;
}
.carpoints > li {
  list-style: disc;
  margin-bottom: 5px;
}

.car-specs {
  margin-bottom: 10px;
}
.car-specs > li {
  font-size: 12px;
  margin-bottom: 5px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.car-specs > li i {
  vertical-align: top !important;
  margin-right: 5px;
}
.car-specs > li .sprite {
  opacity: 0.5;
  margin-right: 10px;
}

.stock-listed {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 15px;
}
.stock-listed > li:before {
  display: inline-block;
  width: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  height: 5px;
  margin-right: 10px;
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.12);
  content: "";
  color: rgba(0, 0, 0, 0.37);
}
.stock-listed > li:first-child:before {
  display: none;
}

.lead-circle {
  width: 90px;
  height: 90px;
  border: solid 5px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  padding: 15px 5px 10px 5px;
  text-align: center;
}
.lead-circle .pie_progress__number {
  font-size: 22px;
}
.lead-circle .pie_progress__label {
  font-size: 12px;
}
.lead-circle:hover {
  background: #E46536;
  color: #ffffff;
}

.editBtn {
  font-size: 12px;
  margin-left: 10px;
}

.img-box {
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  padding: 1px;
}
.img-box .link {
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.37);
  display: block;
  cursor: pointer;
}
.img-box img {
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.inlinecheck label {
  display: inline-block;
  margin-right: 10px;
}

.img_thumb {
  padding: 0px;
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  line-height: 195px;
  text-align: center;
  cursor: pointer;
}

.actionbtn .btn {
  text-align: center;
  padding: 5px 10px;
}
.actionbtn .btn i {
  font-size: 20px;
  opacity: 0.87;
}
.actionbtn .btn:hover i {
  opacity: 1;
}

.btn-group .btn {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-group .btn:hover i {
  background-position-y: -73px;
}

.carmove .img-height {
  max-height: 126px;
}

@media (min-width: 992px) {
  .carpoints {
    min-height: 110px;
  }
  .carpoints > li {
    float: left;
    width: 50%;
    list-style: disc;
    margin-bottom: 5px;
  }

  #carstock .carpoints {
    min-height: initial;
    padding: 0px;
  }
}
.search-wrap {
  padding: 15px;
  position: relative;
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
}
.search-wrap .row {
  margin-right: -5px;
  margin-left: -5px;
}
.search-wrap .row .col-sm-6, .search-wrap .row .col-md-2, .search-wrap .row .col-xs-6, .search-wrap .row .col-xs-12 {
  padding-right: 5px;
  padding-left: 5px;
}
.search-wrap label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.search-wrap .css-1okebmr-indicatorSeparator {
  display: none;
}
.search-wrap .css-yk16xz-control, .search-wrap .css-1pahdxg-control {
  height: auto;
}
.search-wrap .css-1wa3eu0-placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.search-wrap .css-tlfecz-indicatorContainer, .search-wrap .css-1gtu0rj-indicatorContainer {
  padding: 8px 4px;
}
.search-wrap .advancesearch {
  font-size: 12px;
  position: absolute;
  left: 50%;
  bottom: -23px;
  background: #ffffff;
  -webkit-border-radius: 0 0 100px 100px;
  -moz-border-radius: 0 0 100px 100px;
  -ms-border-radius: 0 0 100px 100px;
  -o-border-radius: 0 0 100px 100px;
  border-radius: 0 0 100px 100px;
  margin-left: -120px;
  padding: 2px 15px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.search-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-flex .searchitems {
  width: 100%;
  padding: 0px 5px;
}

@media (min-width: 768px) {
  .search-wrap {
    padding-bottom: 0px;
  }

  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-flex .searchitems {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-flex .searchitems {
    width: 14.285;
  }
}
.manageStock h1 {
  font-size: 28px;
  padding-top: 15px;
}

.mrg-r10 {
  margin-right: 10px;
}

/* Manage Result*/
.result-wrap {
  padding: 15px 0px;
  position: relative;
}

.tagsphotos {
  margin-bottom: 15px;
}
.tagsphotos textarea {
  min-height: 30px;
  height: 34px;
  margin-bottom: 5px;
}

.tagscol {
  margin-left: -5px;
  margin-right: -5px;
}
.tagscol > div {
  padding-left: 5px;
  padding-right: 5px;
}

.similarlist > li {
  position: relative;
}
.similarlist > li .thumbnail {
  background-color: #ffffff;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  display: block;
  margin-bottom: 15px;
  padding: 0px;
  -webkit-transition: border 0.2s ease-in-out 0s;
  -moz-transition: border 0.2s ease-in-out 0s;
  -ms-transition: border 0.2s ease-in-out 0s;
  -o-transition: border 0.2s ease-in-out 0s;
  transition: border 0.2s ease-in-out 0s;
}
.similarlist > li .thumbnail .caption {
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.similarlist > li .thumbnail .caption .carpoints {
  min-height: inherit;
  padding-left: 0px;
}
.similarlist > li .thumbnail .caption .carpoints li {
  position: relative;
  padding-left: 10px;
  width: 50%;
  margin-left: 0px;
}
.similarlist > li .thumbnail .caption .carpoints li:before {
  display: inline-block;
  width: 3px;
  height: 3px;
  background: rgba(0, 0, 0, 0.12);
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0px;
}
.similarlist > li .thumbnail .img_thumb {
  line-height: 135px;
}
.similarlist > li .thumbnail .img_thumb img {
  max-height: 135px;
}
.similarlist > li .selectCheckbox {
  background-color: #ffffff;
  padding: 3px 8px 7px 7px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-right: solid 1px #d5d5d5;
  border-bottom: solid 1px #d5d5d5;
}
.similarlist > li .selectCheckbox .form-field {
  margin-bottom: 0px;
}
.similarlist > li .selectCheckbox .gs_control {
  padding-left: 16px;
  display: initial;
}

.table .form-control {
  font-size: 14px;
}

.car-price {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .similarlist > li .thumbnail {
    min-height: 280px;
  }
  .similarlist > li .thumbnail:hover {
    -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .similarlist > li .thumbnail .caption p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .search-wrap {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
    border: 0;
  }
  .search-wrap .btn.btn-primary {
    width: calc(50%);
  }
  .search-wrap .btn.btn-default {
    width: calc(50% - 10px);
  }
  .search-wrap .advancesearch {
    position: initial;
    margin-top: 15px;
  }

  .search-wrap:before {
    content: "";
    width: 24px;
    height: 24px;
    font-family: "dc" !important;
    z-index: 1000;
    font-size: 24px;
  }

  .manageStock {
    padding: 0px;
  }
  .manageStock h1 {
    font-size: 24px;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 5px;
  }
  .manageStock .card {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .manageStock .card .card-body {
    background: #F1F3F6;
  }
  .manageStock .card .card-body .car-list > li {
    background: #ffffff;
    margin-bottom: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
  .manageStock .card .card-body .car-list > li .wrap {
    padding: 0px;
  }
  .manageStock .card .card-body .car-list > li .wrap .col-sm-10 > .row {
    padding: 15px;
  }
  .manageStock .card .card-body .car-list > li .actionbtn {
    display: block;
  }
  .manageStock .card .card-body .car-list .lead-circle {
    margin-bottom: 15px;
  }
}
.num-words {
  text-transform: capitalize;
  opacity: 0.87;
  font-size: 12px;
}

.reason {
  display: none;
}
.reason.active {
  display: block;
}

.imgRejectReasons {
  color: #E24141;
  background: rgba(226, 65, 65, 0.03);
  border: solid 1px rgba(226, 65, 65, 0.12);
  padding: 5px;
}

.reasonlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: outside;
  padding-left: 20px;
}
.reasonlist li {
  display: list-item;
  width: 25%;
  list-style: disc;
  font-size: 12px;
  margin-bottom: 7px;
}

.tagsphotos .recent-list-img {
  background: #f5f5f5;
  border: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
}
.tagsphotos .recent-list-img img {
  height: 80px;
}

.dashboard {
  background: #F1F3F6;
  padding: 15px 0px;
}
.dashboard .card {
  margin-bottom: 30px;
}
.dashboard .card .card-heading {
  background: rgba(30, 51, 94, 0.1);
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.lead-list {
  padding: 15px 0px;
}
.lead-list li {
  text-align: center;
}
.lead-list li:last-child {
  border-right: none;
}
.lead-list li a {
  padding: 10px 10px;
  display: block;
}
.lead-list li a .leadno {
  font-size: 32px;
  margin-bottom: 0px;
}
.lead-list li a .leadlabel {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.lead-bytime {
  width: 100%;
  font-weight: normal;
  font-size: 14px;
}
.lead-bytime .css-yk16xz-control, .lead-bytime .css-1pahdxg-control {
  border: none;
  background: none;
  height: 24px;
  min-height: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.lead-bytime .css-yk16xz-control .css-tlfecz-indicatorContainer, .lead-bytime .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .lead-bytime .css-1pahdxg-control .css-tlfecz-indicatorContainer, .lead-bytime .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
  padding: 2px;
  color: #2176AE;
}
.lead-bytime .css-yk16xz-control .css-1uccc91-singleValue, .lead-bytime .css-1pahdxg-control .css-1uccc91-singleValue {
  font-size: 14px;
  color: #2176AE;
}

@media (min-width: 768px) {
  .lead-list li {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }
  .lead-list li a .leadno {
    font-size: 32px;
  }
  .lead-list li a .leadlabel {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .lead-bytime {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .lead-bytime {
    width: 32%;
  }

  .lead-list li a .leadno {
    font-size: 50px;
  }
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal.modal-lg .modal-dialog {
  max-width: 800px;
}
.modal.modal-xl .modal-dialog {
  max-width: 1140px;
}

.modal-dialog {
  position: relative;
  width: 90%;
  margin: 0.5rem auto;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: solid 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  -ms-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  cursor: pointer;
  opacity: 0.5;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.modal-header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}
.modal-header .close:hover {
  opacity: 0.87;
}
.modal-header button.close {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 0;
  -webkit-appearance: none;
  -moz--webkit-appearance: 0;
  appearance: none;
  outline: none;
}
.modal-header .close {
  padding: 0px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 0rem 0rem 0rem auto;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.modal-header .close:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.modal-title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.428571429;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 300px;
    margin: 10px auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 60px);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 60px);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 60px);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 60px);
  }

  .modal-content {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
    -ms-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 500px;
  }
  .ViewHistory .modal-dialog {
    max-width: 1500px;
  }
  
}
@media (max-width: 360px) {
  .modal .modal-dialog {
    max-width: 300px;
    margin: auto auto;
  }
  .modal.modal-lg .modal-dialog, .modal.modal-md .modal-dialog {
    max-width: 90%;
  }
}
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  list-style-type: none;
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -ms-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
@media (max-width: 991px) {
  .carousel__slide {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .carousel__slide {
    padding-right: 0px;
    padding-left: 0px;
  }
}
.carousel__slide--active {
  display: block;
}

.carousel-slide__content {
  margin-bottom: 19px;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
  font-size: 16px;
}

.carousel-slideimg {
  max-width: 100%;
}

.carousel-slide__author,
.carousel-slide__source {
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
}

.carousel-slide__source {
  font-style: italic;
  color: #888;
}

.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  margin-top: -16px;
  opacity: 0.6;
  transform: translateY(-50%);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  background: rgba(0, 0, 0, 0.54);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  z-index: 9;
}
.carousel__arrow:focus {
  outline: 0;
}
.carousel__arrow:hover {
  opacity: 0.9;
}
.carousel__arrow--left {
  left: 15px;
}
.carousel__arrow--right {
  right: 15px;
}

.crarrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.carousel__indicators li:nth-of-type(n + 2) {
  margin-left: 9px;
}

.carousel__indicator {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #111;
  cursor: pointer;
  opacity: 0.15;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
}
.carousel__indicator:hover {
  opacity: 0.5;
}
.carousel__indicator--active, .carousel__indicator--active:hover {
  opacity: 0.75;
}

.thumbbx {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  margin-top: 10px;
}

.carouse-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.carouse-thumb li {
  margin-right: 4px;
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.carouse-thumb li:hover {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.carouse-thumb li:hover .thumb {
  opacity: 1;
  border: solid 2px rgba(0, 0, 0, 0.12);
}
.carouse-thumb li .thumb {
  display: block;
  opacity: 0.6;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100px;
  height: auto;
  border: solid 2px transparent;
}
.carouse-thumb li .thumb.active {
  border: solid 2px #E46536;
  opacity: 1;
}
.carouse-thumb li .thumb img {
  max-width: 100%;
  width: 100%;
  max-height: 54px;
}

@keyframes myfirst {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
.buyerenguiry-table {
  color: rgba(0, 0, 0, 0.54);
}
.buyerenguiry-table .btn.btn-link {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.buyerenguiry-table tr:hover .btn.btn-link {
  color: #E46536;
}
.buyerenguiry-table tr:hover .btn.updatelead {
  color: #ffffff;
  background: #E46536;
}
.buyerenguiry-table tr td {
  width: 19%;
}
.buyerenguiry-table tr td:nth-child(3), .buyerenguiry-table tr td:nth-child(4) {
  width: 15%;
}
.buyerenguiry-table tr td:last-child {
  width: 13%;
}
.buyerenguiry-table tr td #customer_name_ .source-icon {
  margin-left: 5px;
}
.buyerenguiry-table .css-1hwfws3, .buyerenguiry-table .css-1uccc91-singleValue {
  font-size: 14px !important;
}

.car-list > li {
  padding: 0px;
  text-align: left;
}

.carname {
  font-size: 16px;
  margin-bottom: 5px;
}

.closerow {
  position: absolute;
  z-index: 99;
  right: 15px;
  top: 12px;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 3px;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.closerow:hover {
  background: rgba(0, 0, 0, 0.87);
}

.fav-car {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  line-height: 20px;
}
.fav-car.active .ic-heart {
  background-position: -92px -35px;
}

.css-26l3qy-menu {
  white-space: nowrap;
  width: auto !important;
  min-width: 100%;
}
.css-26l3qy-menu .css-4ljt47-MenuList, .css-26l3qy-menu .css-4ljt47-MenuList > div {
  white-space: nowrap;
}

.actionbar {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: #ffffff;
  padding: 10px 15px;
  z-index: 99;
  text-align: center;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.54);
  -ms-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.54);
  -o-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.54);
}

.sharepopup .sharedetail-scroll {
  max-height: 180px;
  overflow: hidden;
  overflow-y: auto;
}
.sharepopup .share-listcar {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding: 10px 0px;
}
.sharepopup .share-listcar:hover {
  background: rgba(0, 0, 0, 0.03);
}
.sharepopup .share-listcar .form-field {
  margin-bottom: 0px;
}
.sharepopup .share-listcar label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #2176AE;
}
.sharepopup .share-listcar i {
  vertical-align: top;
}

.modal .modal-body .carousel .carousel__slides .carousel-slide {
  height: 410px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-flow: column;
  background: rgba(0, 0, 0, 0.12);
  align-items: center;
  justify-content: center;
}
.modal .modal-body .carousel .carousel__slides .carousel-slide img {
  max-height: 410px;
}

.classifiedCheck .tooltip {
  left: -72px;
  min-width: 160px;
}

@media (max-width: 767px) {
  .modal .modal-body .carousel .carousel__slides .carousel-slide {
    height: auto;
  }
  .modal .modal-body .carousel .carousel__slides .carousel-slide img {
    max-height: 260px;
    width: 100%;
  }
}
.pagesteps {
  padding: 15px;
  background-color: #ffffff;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;
}
.pagesteps li {
  display: inline-block;
  position: relative;
  margin-right: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.pagesteps li:before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  border-bottom: 2px solid #ebebeb;
  float: left;
  margin-top: 15px;
  position: absolute;
  right: -100%;
}
.pagesteps li:last-child:before {
  display: none;
}
.pagesteps li a {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
  height: 32px;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  padding: 0px 10px 0 0;
  float: left;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.pagesteps li a .pagecount {
  display: inline-block;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background: #cccccc;
  margin: -1px 8px 0px -1px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.pagesteps li a.active, .pagesteps li a:hover {
  background-color: #fae0d8;
  border: solid 1px rgba(228, 101, 54, 0.3);
}
.pagesteps li a.active .pagecount, .pagesteps li a:hover .pagecount {
  background-color: #E46536;
  color: #ffffff;
}

.btn .ic-next-w, .btn .ic-prev-b {
  margin-right: 5px;
}

.map-wrap {
  border: solid 1px rgba(0, 0, 0, 0.12);
  min-height: 600px;
  background: url(../images/mapscreen.png) no-repeat;
  background-size: cover;
}

.week-day {
  padding-top: 25px;
}

#popoverInventory {
  left: -80px;
}
#popoverInventory .arrow {
  left: 86px;
}

.signUp-container {
  min-height: 340px;
}
.signUp-container h1 {
  margin-bottom: 5px;
}
.signUp-container p {
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 10px;
}
.signUp-container p.login-p {
  margin-bottom: 30px;
}
.signUp-container p.login-p.smtxt {
  font-size: 14px;
}
.signUp-container .forgotP {
  float: right;
  color: #E46536;
  font-size: 12px;
  font-weight: 500;
}
.signUp-container .dc-remember {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.mrg-b30 {
  margin-bottom: 30px;
}

.btn.button-rounded {
  padding: 0 25px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  background: rgba(30, 51, 94, 0.12);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
  box-shadow: none;
  text-transform: capitalize;
  height: 40px;
  margin-right: 7px;
  outline: none;
}
.btn.button-rounded.active {
  background: #1e335e;
  color: #ffffff;
}

.editmobile {
  position: absolute;
  top: 10px;
  font-size: 14px;
  right: 15px;
  color: #E46536;
}

/* contact */
.contactInfo {
  padding: 10px 0px 20px;
  font-size: 18px;
  width: 80%;
}

.contact_Ic {
  border-radius: 50%;
  background: rgba(30, 51, 94, 0.12);
  text-align: center;
  float: left;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 30px;
}

.phonewithcountry {
  padding: 1px;
  display: flex;
  width: 100%;
}

.phonewithcountry .countrycode {
  width: 107px;
  padding-top: 23px;
}

.phonewithcountry .countrymobile {
  display: flex;
  padding-left: 10px;
  width: 100%;
}

.phonewithcountry .countrymobile .form-field {
  width: 100%;
}

#homePage {
  position: inherit;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
}
#homePage .leftBlueBg {
  position: relative;
  color: #ffffff;
}
#homePage .leftBlueBg .lgHeading {
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  font-weight: 100;
  line-height: 1.1234em;
  margin-bottom: 0px;
}
#homePage .leftBlueBg p {
  margin-bottom: 10px;
}
#homePage .blueBg {
  padding: 15px 30px;
  position: relative;
  right: 0;
  top: 0;
  background: #1e335e url(https://dealercentral.gaadi.com/images/bg_with_road.jpeg) no-repeat bottom right;
  width: 100%;
  height: 100%;
  padding: 15px;
  text-align: center;
  overflow: hidden;
}
#homePage .blueBg .animationImage {
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 999;
  width: 100px;
  transition-timing-function: ease-out;
  animation: caranimate 4s infinite;
  -webkit-animation: caranimate 4s infinite;
  animation-direction: normal;
}
#homePage .logoSec {
  text-align: center;
}
#homePage .logoSec img {
  max-height: 32px;
}
#homePage .rightWhiteBg {
  width: 100%;
  position: relative;
  float: left;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
}
#homePage .rightWhiteBg .navbar {
  background: transparent;
  padding: 10px;
}
#homePage .rightWhiteBg .navbar .navbar-nav {
  width: 100%;
}
#homePage .rightWhiteBg .navbar .navbar-nav li {
  width: 33.3333%;
  font-size: 14px;
  text-align: center;
  position: relative;
  float: left;
  padding: 8px 10px;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: solid 2px transparent;
}
#homePage .rightWhiteBg .navbar .navbar-nav li.active {
  border-bottom: solid 2px #E46536;
  color: #E46536;
}
#homePage .rightWhiteBg footer {
  padding: 50px 0;
  background: transparent;
}
#homePage .rightWhiteBg footer .footer {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.37);
  position: initial;
  padding-right: 15px;
  text-align: right;
}

@media not all, (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
  }
}
@-webkit-keyframes caranimate {
  0% {
    left: -500px;
  }
  100% {
    left: 100%;
  }
}
@keyframes caranimate {
  0% {
    left: -500px;
  }
  100% {
    left: 100%;
  }
}
.playStore {
  margin-bottom: 30px;
}
.playStore a {
  display: inline-block;
}
.playStore img {
  max-width: 130px;
}

.homeMobile, .img-mobile-bg1 {
  display: none;
}

.videoSec {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 48px;
  width: 172px;
  /* margin-left: 223px; */
}

.slide-wrapper {
  padding: 15px;
  width: 100%;
}

.carousel .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.carousel .carousel-inner .item {
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -ms-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.carousel .carousel-inner > .item.next, .carousel .carousel-inner > .item.active.right {
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: 0;
}
.carousel .carousel-inner > .item.prev, .carousel .carousel-inner > .item.active.left {
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  top: 0;
}
.carousel .carousel-inner > .item.next.left, .carousel .carousel-inner > .item.prev.right, .carousel .carousel-inner > .item.active {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0;
}
.carousel .carousel-inner .slide-wrapper .sm {
  width: 100%;
}

.carousel-indicators {
  display: none;
}

@media (min-width: 768px) {
  #homePage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-family: Roboto, Arial, sans-serif;
  }
  #homePage .logoSec {
    text-align: left;
  }
  #homePage .rightWhiteBg {
    width: 50%;
  }
  #homePage .rightWhiteBg .navbar .navbar-nav {
    width: auto;
    float: right !important;
    padding-right: 30px;
  }
  #homePage .rightWhiteBg .navbar .navbar-nav li {
    width: auto;
    margin-right: 10px;
    float: left;
    white-space: nowrap;
    cursor: pointer;
  }
  #homePage .leftBlueBg {
    width: 50%;
    height: 100%;
    float: left;
  }
  #homePage .leftBlueBg .lgHeading {
    font-size: 48px;
    margin-top: 30px;
  }
  #homePage .leftBlueBg p {
    margin-bottom: 15px;
  }

  .playStore img {
    max-width: 100%;
  }

  .homeMobile {
    position: absolute;
    bottom: 13px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .blueBg .animationImage {
    display: block;
  }
  .blueBg .animationImage img {
    max-width: 70px;
  }

  .slide-wrapper {
    padding: 40px 0px 0px 80px;
    max-width: 550px;
  }

  .slide-wrapper .sm {
    width: 360px;
  }

  .carousel-indicators {
    position: fixed;
    display: block;
    text-align: center;
    top: 15%;
    right: 10px;
    bottom: 0;
    margin: auto;
    height: 60px;
    right: 10px;
    left: auto;
    width: auto;
  }
  .carousel-indicators li {
    display: block;
    width: 15px;
    height: 15px;
    text-indent: -999px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5px;
    border: 3px solid #fff;
    background: rgba(0, 0, 0, 0.12);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .carousel-indicators li.active {
    border: 3px solid rgba(255, 255, 255, 0.77);
    background: #E46536;
  }
  .carousel-indicators li.active:hover, .carousel-indicators li.active:focus, .carousel-indicators li.active:active {
    border: 3px solid rgba(255, 255, 255, 0.77) !important;
    background: #E46536 !important;
  }
  .carousel-indicators li:hover, .carousel-indicators li:focus, .carousel-indicators li:active {
    border: 3px solid rgba(228, 101, 54, 0.12);
    background: #ffffff;
  }
}
body {
  background: #F1F3F6;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Arial, sans-serif;
}

.btn.buyerEnquiryBtn{ 
  padding: 10px;
}

.mrg-t22 {
  margin-top: 22px;
}
.imgwrap-favourite{
position: relative;
}

.sold-img{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.data-table table thead tr th {
  padding:
    15px 9px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000;
  white-space: nowrap;
  text-align: left;
}

.data-table table thead tr {
  background: #e3e7f1;
  border-radius:
    6px;
}

.data-table table {
  table-layout: fixed;
  background: #fff;
  border-radius:
    6px;
}

.data-table table tbody tr {
  border-bottom:
    1px solid rgba(0, 0, 0, .06);
}

.data-table table tbody tr td {
  padding:
    13px 9px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, .6);
  text-align: left;
}

.data-table table tbody tr td h6{
  padding:
    13px 9px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, .6);
  text-align: left;
}

.data-table table tbody tr td:first-child {
  padding-left:
    30px;
}

.data-table table thead tr th:first-child {
  border-radius:
    6px 0 0 0;
  padding-left:
    30px;
}

.data-table table thead tr {
  background: #e3e7f1;
  border-radius:
    6px;
}

.data-table table thead tr th:last-child {
  border-radius: 0 6px 0 0;
  padding-right:
    20px;
}

.tab-list {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tab-list .nav-link {
  border-bottom:
    2px solid transparent;
  padding-bottom:
    8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, .87);
  margin-right: 30px;
  cursor: pointer;
}

.tab-list .nav-link.active {
  font-weight: 600;
  color: rgba(11, 189, 221, .87);
  border-bottom:
    2px solid #0bbddd;
}

.inactive-popup-outer .modal-content{
  border-radius: 15px;
  padding: 25px 30px;
  width: 420px;
}

.inactive-popup-outer .modal-header{
  border-bottom: 0px !important;
}

.inactive-popup-outer .modal-body{
  padding: 0px 20px !important;
}

.inactive-popup-outer .modal-title{
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 8px;
}

.inactive-popup-outer .modal-header .close{
  position: absolute;
  right: 20px;
  top: 20px;
}

.inactive-popup-outer .btn-save-close{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.nowrap{
  white-space: nowrap;
}

.dealer-listing .inactive_reason{
  width: 14%;
}

.dealer-listing .city-name{
  width: 10%;
}
.margin-15{margin-top: 16px;}
.inventory_inactive_funding span{
  display: block;
  width: 100%;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 500;
}
.inventory_inactive_funding {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
}

.category-history-popup .modal-body {
  height: calc(100vh - 120px);
  overflow: auto;
}

.inventory_funding_detail{
  margin-bottom: 10px;
}
.inventory_funding_detail ul  {
  display: flex;
  flex-wrap: wrap;
}
.inventory_funding_detail ul li {
  width: calc(20% - 10px);
  margin-right: 10px;
  margin-bottom: 15px;
}
.inventory_funding_detail ul li span{
  display: block;
  color: #000;
  font-size: 15px;
  padding-bottom: 5px;
}
.funding-detail-with-img ul li:last-child{
  width: 100%;

}
.funding-detail-with-img ul li:last-child span{
  margin-bottom: 10px;
}
.funding-img{
  display: flex;
  flex-wrap: wrap;
}
.funding-img li {
width: 120px !important;
height: 120px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}
.w-100{
  width: 100% !important;
}
.ic-info-icon:before {
  content: "\e95f";
}
.latlonTooltip .bs-tooltip-auto[x-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-left: 4px;
}

.dealer_dashboard_select__control{
    border: none !important;
    height: 24px !important;
    box-shadow: none !important;
    min-height: inherit !important;
    background: none !important;
}
.dealer_dashboard_select__single-value{
  font-size: 14px !important;
  color: #2176ae !important;
}
.dealer_dashboard_select__indicator{
  padding: 2px !important;
  color: #2176ae !important;
}

.stock_image_list::after {
  display: block;
  content: "Profile Photo";
  position: absolute;
  top: 0;
  left: 0;
  background: #31b93e;
  width: 80px;
  height: 18px;
  padding: 2px 5px;
  font-size: 12px;
  color: #fff;
  border-radius: 0 2px 2px 0;
}

.stock_image_list{
  position: relative;
}

.css-1jqq78o-placeholder{font-size: 12px !important;}

.share-doc-verified {
  display: flex;
  padding-top: 30px;
  padding-left: 0px !important;
}

.share-doc-verified .btn-link {
  position: relative;
  top: -8px;
  margin-left: 5px;
}
.changeStatus h3{
  margin-bottom: 0px;
  font-weight: bold;
}
.changeStatusOrg{
  padding-bottom: 15px;
  font-weight: bold;
}

.changeStatusCurValue, .changeStatusCurOption{
  font-weight: bold;
}

.mapped_users_outer ul li{
  border-bottom: 1px solid #000000;
  padding: 10px 0px;
  text-align: left;
}

.mapped_users_outer ul li label{
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px;
  opacity: 1;
  display: contents;
}

.mapped_users_outer {
  overflow-y: hidden !important;
 
}

.mapped_users_outer .modal-body{
  max-height: 400px;
  overflow: auto;
}

.mapped_user_outlet ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mapped_user_outlet ul li{
  width: 48%;
  border: 0px;
  background-color: #d9d9d9;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.mapped_user_outlet h3{
  padding-bottom: 10px;
  padding-top: 100px;
  padding-left: 15px;
}

.google-sign-in-button {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
      
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.google-sign-in-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
    background-color: #eeeeee;
}

.google-sign-in-button:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.google-sign-in-button:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}
.add-multi-address{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-multi-address button{
  margin-right: 10px;

}
.esign-img{
  background: #F6F6FF;
  border-radius: 15px;
  padding: 10px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}