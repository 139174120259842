.if-applications{
.upload-file-outer{

    .image-uploader-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 0 0;
        .display-box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #EFF2F9;
            min-height: 323px;
            width: 100%;
            padding-top: 40px;
            border-radius: 8px;
            border: 1px solid #EFF2F9;
            &:hover,
            &.drag-over {
                .icon-text-box {
                    // background-color: #96a599;
                    cursor: pointer;
                    // color: #fff;
                    // text-shadow: 0px 1px 0 #999;
                    .upload-icon {
                        // transform: translateY(-20px);
                        // animation: bounce 1s ease-in-out infinite;
                    }
                }
            }
            .icon-text-box {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                text-shadow: 0px 0px 0 #999;
                transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in,
                    text-shadow 0.2s ease-in;
                .upload-icon {
                   
                    margin: 0;
                    box-sizing: border-box;
                    transform: translateY(0px);
                    transition: transform 0.2s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $white;
                    height: 60px;
                    width: 60px;
                    border-radius: 100%;
                    img{
                        max-width: 30px;
                    }
                    i {
                        font-size: 38px;
                    }
                }
                .upload-button,
                .cancel-upload-button {
                    margin: 0 10px;
                    position: relative;
                    z-index: 9999;
                }
    
            }
            .upload-image-input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .upload-txt {
        padding-top: 24px;
        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $heading-color;
        }
        span {
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba($heading-color, 0.5);
            padding: 0px 50px;
            display: block;
        }
        .or-txt {
            text-transform: uppercase;
            padding-top: 20px;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $heading-color;
        }
        .error-msg {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #e02020;
            opacity: 1;
            display: block;
            padding: 5px 50px;
        }
    }
    
    .file-btn {
        min-width: 177px;
        box-shadow: none;
        margin: 23px 0px 30px;
    }
    .upload-again-btn {
        margin-top: 15px;
    }
    
    .upload-file-name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 20px;
    
        span.suceess-icn {
            height: 18px;
            width: 18px;
            background: #44d7b6;
            border-radius: 100%;
            color: #fff;
            font-size: 13px;
            font-weight: bold;
            margin-right: 5px;
        }
        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $heading-color;
            padding-left: 5px;
        }
        .ic-clearclose {
            background: white;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }
        .upload-button {
            display: none;
        }
    }
    }
.pdf-gallery {
    i {
        font-size: 80px;
    }
}

.f-50 {
    font-size: 50px;
}
}