.if-applications{
.single-select {
    .react-select__placeholder {
        font-size: 13px;
        color: $heading-color;
        position: relative;
        top: 7px;
        webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
    }
  
    .react-select__indicators {
        svg {
            display: none;
        }
    }
  
    .react-select__indicator-separator,
    .css-109onse-indicatorSeparator {
        display: none;
    }
  
    .react-select__control--menu-is-open,
    .react-select__control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;
        padding: 6px 2px;
  
        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }
  
    .react-select__indicators {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
  
    .react-select__single-value,
    .css-1v99tuv {
        font-size: 12px;
    }
  
    .react-select__menu-list,
    .css-11unzgr {
        font-size: 12px;
    }
  
    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;
  
        input {
            position: relative;
            top: 3px;
        }
    }
  
    .react-select__single-value {
        max-width: 100%!important;
    }

    .react-select__value-container--has-value {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 11px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -7px;
           
        }
    }
    .react-select__value-container{
        overflow: inherit !important;
        background: #fff;
        padding-left: 13px;
        padding-right: 13px;
    }

    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 11px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -7px;
        }
    }
  }
}