.if-applications{
.track-insurance-outer{
    padding: 20px;
    width: 360px;
    max-width: 100%;
    margin: 0 auto;
    background-color: $white;
    .sub-heading{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        padding-top: 30px;
        display: block;
        padding-bottom: 20px;
    }
    h1{
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;

    }
    .top-logo{
        text-align: center;
    }
}
.insurance-claim-detail{
    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0 40px;
        li{
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            text-align: center;
            position: relative;
            padding-right: 12px;
            &::after{
                content: '';
                background: $heading-color;
                height: 3px;
                width: 3px;
                border-radius: 100%;
                position: absolute;
                top: 6px;
                left: -8px;
            }
            &:first-child{
                &::after{
                    display: none;
                }
            }
            &:last-child{
                padding-right: 0px;
            }

        }
    }
}
.claim-status-bg{
    background: #D6FAE2;
    border-radius: 20px 0  20px 0 ;
    padding: 25px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    height: 129px;
    &::after{
        content: "";
        background: url(../../images/claim-status-bg.svg)no-repeat;
        position: absolute;
        right: 0px;
        bottom: 0px;
        height: 127px;
        width: 121px;
        opacity: 70%;
        
    }
    span{
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: $heading-color;
display: block;
padding-bottom: 5px;

    }
    p{
font-size: 11px;
font-weight: 400;
text-align: left;
color: $heading-color;
z-index: 1;
position: relative;
    }

    .next-arrow-bg{
        background: rgba($white, 0.8);
        height: 40px;
        width: 40px;
        border-radius: 20px 0 20px 0;
        display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    }  
}
.upload-doc-bg{
    background: #FAF2D6;
    &::after{
        content: "";
        background: url(../../images/upload-doc-bg.svg)no-repeat;
    }
    p{
        color: #ED4949;
    }
}
.claim-status-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading-back-arrow{
        display: flex;
    align-items: center;
    }
    .ic-arrow_back{
        font-size: 20px;
        color: $heading-color;
        margin-right: 15px;
    }
    h2{
font-size: 15px;
font-weight: 500;
line-height: 22.5px;

    }
.btn-share{
    min-width: auto;
    padding: 0px 10px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 6px;
    i{
    margin-right: 5px;
    }
}
}

.claim-customer-detail{
    box-shadow: 0px 2px 6px 0px #0000000D;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    padding:20px;
    margin-top: 15px;
    ul{
        li{
            font-size: 11px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $border-color;
            &:last-child{
                border-bottom: 0px;
            }

        }
    }
}
.customer-claim-status-detail{
    margin-top: 30px;
    ul{
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            padding: 10px 0 10px 25px;
            position: relative;
            .date-txt{
                font-size: 10px;
                font-weight: 400;
                line-height: 30px;

            }
            &::before{
                content: "  ";
                background: #DEE5EF;
                height: 10px;
                width: 10px;
                position: absolute;
                left: 0px;
                border-radius: 100%;
                top: 20px;
            }
            &.completed{
                &::before{
                    background: #19B657;

                }
            }
            &.active{
                background: #ECF1F8;
                width: calc(100% + 40px);
                margin: 10px -20px;
                padding: 10px 20px 10px 45px;
                align-items: flex-start;
                &::before{  
                    background: #008CF0;
                    border: 7px solid #c9e2f7;
                    left: 12px;
                    top: 13px;
                }
                label{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 30px;
                    color: $heading-color;
                }
            }
        }
    }
}

.uplaod-doc-card{
    padding: 15px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-top: 10px;
     border-radius: 10px;
    .uplad-doc-heading{
        display: flex;
        align-items: center;
        i{
            background: $white;
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            border-radius: 100%;
        }
        h3{
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            margin-left: 10px;

        }
    }
    .upload-doc-btn{
        background: #2ABB64;
        font-size: 9px;
        font-weight: 500;
        line-height: 13.5px;
        text-align: left;
        color: $white;
        border-radius: 44px;
        padding: 0px 10px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            margin-right: 5px;
        }

    }
}

.bg-one{
    background: #BFF8FC;
}

.bg-two{
    background: #D8EAFF;
}

.bg-three{
    background: #FFF1D6;
}

.bg-four{
    background: #C5F8EB;
}

.bg-five{
    background: #FFE4D8;
}


.accordain-conent-outer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    .img-bx-select-opt{
        width: calc(33.33% - 10px);
        margin: 10px 5px 0;
        .image-bx{
            height: 84px;
        }
    }
    .more-file-upload input[type="file"] {height: 84px;}

}

.doc-accordain-list{

    .accordion__button {
        position: relative;
        width: 100%;
        cursor: pointer;
        &:after {
            content: "\e917";
            font-family: dc !important;
            position: absolute;
            right: 0;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .accordion__panel{
        margin-top: -20px;
    box-shadow: 0px 2px 6px 0px #0000000D;
    border: 1px solid #D6D6D6;
    border-radius: 0px 0 10px 10px;
    border-top: 0px;
    padding: 30px 10px 10px;

    }
    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        content: "\e91a";
        font-family: dc !important;


    }
    h3{
        width: 60%;
    }

    .uploaded-tag{
        background: $white;
         height: 22px;
         width: 22px;
         border-radius: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
        i{
            color: #2ABB64;
        }
    }
}
}