.if-applications{
.loader-main {
  margin-top: 0px;
}

.loader {
  color: $heading-color;
  position: fixed;
  box-sizing: border-box;
  //   left: -9999px;
  //   top: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 99;
}
.loader:after,
.loader:before {
  box-sizing: border-box;
  display: none;
}
.loader.is-active {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 70px;
  width: 100%;
}
.loader.is-active:after,
.loader.is-active:before {
  display: block;
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes blink {
  0% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.loader[data-text]:before {
  //   position: fixed;
  //   left: 0;
  //   top: 50%;
  color: currentColor;
  //   font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: rgba($heading-color, 0.54);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 50px;
  opacity: 0.54;
}
.loader[data-text=""]:before {
  content: "Loading";
}
.loader[data-text]:not([data-text=""]):before {
  content: attr(data-text);
}
.loader[data-text][data-blink]:before {
  animation: blink 1s linear infinite alternate;
}
.loader-default[data-text]:before {
  //   top: calc(50% - 63px);
}
.loader-default:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border: 2px solid $accent-color;
  border-left-color: transparent;
  border-radius: 50%;
  //   top: calc(50% - 24px);
  //   left: calc(50% - 24px);
  animation: rotation 1s linear infinite;
}
.loader-default[data-half]:after {
  border-right-color: transparent;
}
.loader-default[data-inverse]:after {
  animation-direction: reverse;
}

.loader-full {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: rgba($heading-color, 0.7);
  z-index: 999;
  .loader.is-active {
    height: 100%;
  }
}

.leadloader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  float:left;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.wrapper {
  position: relative;
}
}