.if-applications{
.lead-detail-top-ribben{
  background: $white;
  padding: 15px 20px;
  width: 100%;
  ul{
    display: flex;
    align-content: center;
    li{
      font-size: 12px;
      margin-right: 50px;
      &:last-child{
        margin-right: 0px;
      }
      span{
        color: $heading-color;
        display: block;
      }
    }
  }

}
.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-highlight{
  color: #0BBDDD !important;
}
}